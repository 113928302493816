

body, html, #root {
  height: 100%;
}

.ant-drawer-header {
  display: none!important;
}

.ant-picker-calendar-date-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-slider, .slick-list,  .slick-track, .slick-slide, .slick-slide > *, .slick-slide > * > * {
  height: 100%;
}

.ant-page-header-heading-left {
  margin-left: 8px!important;
}
.ant-card-body {
  padding-left: 12px!important;
  padding-right: 12px!important;
}

.ant-pro-page-container-children-content, .ant-layout-content, .ant-pro-page-container {
  margin: 0px!important;
}

/* * { 
  border: 1px solid #000;
} */


.rbc-toolbar-label {
  font-size: 24px;
  color: red;
}

.rbc-off-range-bg {
  background-color: #333!important;
  position: relative;
  overflow: hidden;
}

/* .rbc-off-range-bg:after {
  content: "";
  background-color: red;
  position: absolute;
  height: 3px;
  width: 150%;
  transform: rotate(45deg);
  left: 0;
  top: 0;
  transform-origin: 0 0;
} */